.theme-esc {
  --color-primary: #007af7;
  --color-divider: #f1f1f1;
  --color-primary-overlay: #3599ff05;
  --color-background: #edf4f9;
}
.theme-esc .c-app {
  background-color: var(--color-background);
}
.theme-esc .c-body.content {
  position: relative;
}
.theme-esc .title-user {
  font-size: 14px;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}

.theme-esc .c-header > ul {
  min-width: 0px;
}
.theme-esc .c-header > ul > li{
  width: 100%;
}
.theme-esc .c-header > ul > li > div > button{
  width: 100%;
}
.theme-esc .p-user {
  display: flex;
  align-items: center;
}
.theme-esc .p-user:focus {
  box-shadow: none;
}
// @media (max-width: 576px) {
//   .theme-esc .title-user {
//     display: none;
//   }
// }
.theme-esc .btn-esc-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

