.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto;
}
.modal-header button.close {
  background-color: transparent;
  border: 0;
}
.modal-header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.form-control[type=color]{
  width: 50px;
  height: 30px;
}

.form-control-range{
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.border-dashed{
  border: 1px dashed #d8e2ef!important;
  border-bottom-width: 0!important;
}